import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/detentionWarning/pageList", formData);
}

function schoolList(){
    return request.post(constant.serverUrl + "/bus/detentionWarning/schoolList");
}

function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/bus/detentionWarning/exportXls", formData);
}

export default {
    pageList,schoolList,exportXls
}