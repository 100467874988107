<template>
  <div>
        <el-dialog
        width="30%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'80px'">
                    <el-form-item label="选择学校" prop="schoolId">
                        <el-select v-model="formModel.schoolId" placeholder="请选择学校" style="width:100%">
                          <el-option
                              v-for="item in schoolList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="课程名称" prop="name">
                        <el-input v-model="formModel.name" placeholder="请输入课程名称" ></el-input>
                    </el-form-item>
                    <el-form-item label="课程类型" prop="typeName">
                        <template>
                          <el-radio v-model="formModel.typeName" label="校本课">校本课</el-radio>
                          <el-radio v-model="formModel.typeName" label="社团课">社团课</el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item label="课程价格" prop="cost">
                        <el-input v-model="formModel.cost" 
                        placeholder="请输入课程价格" 
                        oninput ="value=value.replace(/[^0-9.]/g,'')">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="课程简介" prop="desc">
                        <el-input
                          type="textarea"
                          :rows="5"
                          placeholder="请输入课程简介"
                          v-model="formModel.desc">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Constant from "@/constant";
import schoolCourseApi from '@/api/bus/schoolCourse'
import detentionWarningApi from '@/api/bus/detentionWarning'
export default {
    props: ["businessKey", "title"],
    data() {
      return{
        formModel: {
          id: "",
          schoolId: "",
          name:"",
          typeName:"",
          cost:"",
          desc:""
        },
        schoolList:[],
        ruleValidate: {
          schoolId: [{ required: true, message: "学校不能为空", trigger: "blur" }],
          name: [{ required: true, message: "课程名称不能为空", trigger: "blur" }],
          typeName: [{ required: true, message: "课程类型不能为空", trigger: "blur" }],
          cost: [{ required: true, message: "课程价格不能为空", trigger: "blur" }],
          desc: [{ required: true, message: "课程简介不能为空", trigger: "blur" }]
        },
        showDialog: true,
        loading: false,
        submitting: false,
        showMapDialog: false,
        selectedRow: null,
      }
    },
    created() {
        var self = this;

        detentionWarningApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    methods: {
      closeDialog() {
        this.$emit("close", false);
      },
      handleSubmit() {
        var self = this;
        this.$refs["form"].validate(valid => {
          if (valid) {
            (function() {
              var id = self.formModel.id;

              if (id == null || id.length == 0) {
                return schoolCourseApi.add(self.formModel);
              } else {
                return schoolCourseApi.update(self.formModel);
              }
            })().then(function(response) {
              var jsonData = response.data;

              if (jsonData.result) {
                self.$message({
                  message: "保存成功!",
                  type: "success"
                });
                self.$emit("close", {
                  result: true,
                  data: jsonData.data
                });
              } else {
                self.$message({
                  message: jsonData.message + "",
                  type: "warning"
                });

                self.$emit("close", {
                  result: false
                });
              }
            });
          }
        });
      },
    },
    async mounted() {
      console.log("mounted");

      var self = this;

      (function() {
          if (self.businessKey.length == 0) {
            return schoolCourseApi.create();
          } else {
            return schoolCourseApi.edit(self.businessKey);
          }
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
              self.formModel = jsonData.data;
            } else {
              self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });

    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
</style>
