<template>
    <div class="schoolCourse-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">在校课程管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
            <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="学校名称" prop="schoolName">
                <el-input v-model="queryModel.schoolName" size="mini"/>
            </el-form-item>
            <el-form-item label="课程类型" prop="typeName">
                <el-select v-model="queryModel.typeName" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in typeNameList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-circle-plus"
            @click="handleAdd(null)"
        >新增</el-button>
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-remove"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
        >删除选中项</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="schoolName" label="学校名称" fixed="left"></el-table-column>
            <el-table-column prop="typeName" label="课程类型" fixed="left"></el-table-column>
            <el-table-column prop="name" label="课程名称" fixed="left"></el-table-column>
            <el-table-column prop="cost" label="课程价格" fixed="left"></el-table-column>
            <el-table-column prop="desc" label="课程描述" fixed="left"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <schoolCourse-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></schoolCourse-detail>
    </div>
</template>
<script>
import schoolCourseApi from '@/api/bus/schoolCourse'
import schoolCourseDetail from "./schoolCourse-detail";
export default {
    name:"busSchoolCourseList",
    data(){
        return{
            queryModel:{
                schoolName:"",
                typeName:"",
            },
            typeNameList:[
                {
                    value:"校本课",
                    name:"校本课"
                },
                {
                    value:"社团课",
                    name:"社团课"
                },
            ],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
        }
    },
    methods:{
        handleAdd(record) {
            this.modalTitle = "新增";
            this.operation = "add";
            this.businessKey = "";

            if (record != null) {
                this.selectedRecord = record;
            } else {
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        handleBatchDelete() {
            var self = this;

            var idList = this.multipleSelection.map(record => {
                return record.id;
            });

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                schoolCourseApi.batchRemove(idList).then(function(response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                    type: "success",
                    message: "删除成功!"
                    });
                }
                });
            });
        },
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("schoolName", this.queryModel.schoolName);
            formData.append("typeName", this.queryModel.typeName);


            schoolCourseApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                list.splice(i, 1);
                rs = true;
                break;
                }

                if (list[i].children != null) {
                rs = this.loopDelete(list[i].children, id);

                if (rs) {
                    break;
                }
                }
            }

            return rs;
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                schoolCourseApi.remove(record.id).then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    var rs = self.loopDelete(self.tableData, record.id);

                    self.$message({
                        type: "success",
                        message: "删除成功!"
                    });
                    }
                });
            });
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;
                this.changePage(this.pageIndex);
            }
        },
    },
    components: {
        "schoolCourse-detail": schoolCourseDetail
    },
    mounted() {
        this.changePage(1);
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.schoolCourse-list{
    text-align: left;
}
</style>